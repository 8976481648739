
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "LibraryHeader",
  props: ["mentor"],
  computed: {
    ...mapState("library_content", ["libraryItemsCount"])
  }
});
